:root {
  --menu-background: #fff;
  --menu-border-color: #A48465;
  --menu-font-color: #000;
  --primary-button-bg-color: #000;
  --secondary-button-bg-color: #fff;

  --link-hover: rgb(107, 102, 102);

  --color-text-inverse: #fff;
  --color-background-inverse: #000;
  --color-border-inverse: #000;

  --secondary-color: #d3531a;
  --secondary-color-hover: #d67e58;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial;
  width: 100%;
}


body {
  width: 100%;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: none; /* no underline */
}

a:hover {
  color: var(--link-hover);
}

input:focus {
  outline: none;
}

.main-layout {
  display: grid;
  grid-template-columns: 100vw;
  margin-bottom: 100px;
}

.logo-holder {
  position: relative;
}

.nav-bar {
  height: 52px;
  padding: 15px 0;
  margin: 0 5px;
}

.nav-bar_fixed {
  position: fixed;
  z-index: 2;
  background-color: var(--menu-background);
  top: 0;
  padding: 25px 20px;
  left: 0;
}

.nav-bar_holder {
  display: flex;
  width: 95%;
  margin: 0 auto;
}

.nav-burger_holder {
  width: 33.33333%;
  display: flex;
  align-items: center;
}

.nav-burger {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.nav-burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--secondary-color);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav-burger span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-burger span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-burger span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-burger.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

.nav-burger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.nav-burger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
  left: 8px;
}

.cart-drawer {
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 3;
  height: 100vh;
}

.cart-drawer.close {
  right: -100vw;
  animation: slideOut 0.3s;
  animation-fill-mode: forwards;
}

.cart-drawer.open {
  right: -100vw;
  animation: slideIn 0.3s;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from { right: -100vw; }
  to { right: 0; }
}

@keyframes slideOut {
  from { right: 0; }
  to { right: -100vw; }
}

.cart-drawer .cart-drawer_header {
  height: 60px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 30px;
  border-left: 2px solid #e0dddd;
}

.cart-drawer .cart-drawer_header span {
  text-align: center;
  width: 15px;
  cursor: pointer;
}

.cart-drawer_content {
  padding: 30px 0 0 25px;
  background-color: var(--menu-background);
  border-left: 2px solid #e0dddd;
  height: 100vh;
}

.cart-drawer_show-products {
  min-height: 200px;
  height: 50vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scrollbar-color: #f4f4f4 transparent;
  margin-bottom: 2em;
}

.cart-drawer_show-products::-webkit-scrollbar-thumb {
  background-color: #f4f4f4;
  border-radius: 25px;
  border: 4px solid white;
}

.cart-drawer_show-products::-webkit-scrollbar {
  background-color: transparent;
}

.cart-drawer_show-product {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-bottom: 20px;
  height: fit-content;
}

.cart-drawer_show-product img,
.cart-drawer_show-product h1 {
  cursor: pointer;
}

.cart-drawer_show-product h1 {
  font-size: 12px;
  font-weight: normal;
}

.cart-drawer_show-product p {
  margin-top: 10px;
  font-size: 14px;
  color: #8b8b8b;
  font-weight: normal;
}

.cart-drawer_show-product_desciption {
  padding: 10px 10px 0 10px;
}

.cart-drawer_product-remove {
  display: flex;
  justify-content: center;
}

.cart-drawer_product-remove button {
  color: var(--menu-font-color);
  font-size: 14px;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.cart-drawer_empty-text {
  padding: 30px 22px 0 22px;
  background-color: var(--menu-background);
  border-left: 2px solid #e0dddd;
  height: 100vh;
  font-style: italic;
  color: #707070;
  font-size: 18px;
}

.cart-drawer_total {
  font-size: 14px;
  height: 43px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
}

.cart-drawer_total b {
  width: fit-content;
  padding-right: 7px;
}

.cart-drawer_checkout-buttons {
  display: flex;
  padding-right: 25px;
}

.cart-drawer_checkout-buttons button {
  height: 42px;
  border-radius: none;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.cart-drawer_checkout-buttons button:nth-child(1) {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: none;
  background-color: var(--secondary-button-bg-color);
  color: var(--menu-font-color);
}

.cart-drawer_checkout-buttons button:nth-child(2) {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
  color: var(--color-text-inverse);
  background-color: var(--primary-button-bg-color);
}

.cart-drawer_checkout-buttons button:hover {
  color: #a5a5a5;
}

.logo-holder {
  position: relative;
  width: 33.33333%;
}

.cart-holder {
  width: 33.33333%;
  display: flex;
  flex-direction: row-reverse;
}

.cart {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.logo {
  text-align: center;
  padding-top: 10px;
}

.logo img {
  width: 78px;
}

.cart-holder img {
  width: 30px;
}

.cart-count {
  width: 20px;
  height: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -13px;
  top: -6px;
  font-weight: bold;
  font-size: 12px;
  background-color: var(--color-background-inverse);
  border: 1px solid var(--color-background-inverse);
  color:var(--color-text-inverse);
  transform: none;
  text-align: center;
  border-radius: 20px 20px;
}

.grid-col-span-8 {
  grid-column: span 1;
}

.grid-col-span-9 {
  grid-column: span 1;
}

.menu-bar {
  visibility: hidden;
  opacity: 0;
  height: 50px;
}

.menu-bar.open {
  visibility: visible;
  padding-top: 50px;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
  background-color: #fff;
}

.menu-bar .menu-bar_list {
  padding-left: 45px;
  position: fixed;
  z-index: 1;
  width: auto;
}

.menu-bar .menu-bar_list li {
  list-style-type: none;
  padding: 10px 0;
}

.menu-bar .menu-bar_list li a {
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 300;
}

.menu-bar .social-icons {
  display: flex;
  margin-top: 100px;
}

.menu-bar .social-icons img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.products_list {
  display: flex;
  flex-wrap: wrap;
}

.products_list .products_item {
  position: relative;
  list-style-type: none;
  margin-bottom: 40px;
  padding: 0 0.9375rem;
  flex: 0 0 50%;
  max-width: 50%;
}

.product_item-info {
  height: 50px;
}

.product_item-info .product_item-info_name {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: bold;
}

.behind-img_wrap:hover .behind-img {
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
}

.behind-img_wrap {
  position: absolute;
  width: calc(100% - 0.9375rem * 2);
  height: auto;
  left: 0.9375rem;
  top: 0;
}

.behind-img {
  opacity: 0;
}

.fade-out {
  animation: fadeOut 0.3s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.product-detail {
  display: grid;
  grid-template-columns: 1fr;
}

.product-detail .grid-col-span-4 {
  max-width: 90%;
  margin: 0 auto;
}

.carousel {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  max-width: 667px;
  margin: 0 auto;
}

.carousel::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.carousel-images_wrapper {
  display: flex;
  flex-direction: row;
  transition: transform .4s cubic-bezier(0.1, 0.5, 0.58, 1);
  transition-duration: 0.4s;
}

.carousel-images_wrapper .carousel_image {
  padding: 0 15px;
  max-width: 667px;
}

.carousel_button {
  position: relative;
  width: 56px;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: inherit;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel_button img {
  width: 24px;
}


.carousel_left-button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 17px;
}

.carousel_right-button {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 17px;
}

.product-detail_attributes {
  padding: 100px 7% 0 7%;
}

.product-detail_name {
  margin-bottom: 0.5em;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.product-detail_hashtags  {
  margin-bottom: 1em;
}

.product-detail_hashtag {
  font-size: 12px;
  margin-right: 12px;
  color: var(--secondary-color);
  cursor: pointer;
}

.product-detail_hashtag:hover {
  color: var(--secondary-color-hover);
}

.product-detail_price {
  margin-bottom: 3em;
  font-size: 15px;
}

.product-detail_sizes {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
}

.error_state {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.product-size_span {
  height: 48px;
  width: 60px;
  margin-right: 10px;
  border-top: 1px solid rgb(199, 199, 199);
}

.product-size_span button {
  height: 48px;
  width: 60px;
  color: #000;
  background-color: var(--secondary-button-bg-color);
  cursor: pointer;
  text-decoration: none;
  outline: inherit;
  border: none;
  border-top: 1px solid rgb(197, 197, 197);
}

.product-size_span button:active {
  background-color: rgb(240, 240, 240);
}

.product-size_span button:hover {
  border-top: 1px solid rgb(136, 136, 136);
}

.product-size_disabled {
  border-top: 1px solid rgb(255, 255, 255);
}

.product-size_disabled button {
  cursor: no-drop;
  border-top: 1px solid rgb(255, 255, 255);
}

.product-size_disabled button:hover {
  border-top: 1px solid rgb(255, 255, 255);
}

.product-size_active {
  border-top: 1px solid rgb(0, 0, 0) !important;
  border-bottom: 1px solid rgb(0, 0, 0) !important;
  background-color: rgb(240, 240, 240) !important;
}

.product-detail_sizes_available {
  font-size: 12px;
  height: 14px;
  font-style: italic;
  margin-bottom: 2.5em;
}

.error {
  color: red;
}

.add-to-cart {
  /* margin */
  margin-bottom: 3em;
}

.add-to-cart_button {
  width: 231px;
  height: 46px;
  background-color: var(--secondary-button-bg-color);
  cursor: pointer;
  text-decoration: none;
  outline: inherit;
  border: none;
  border: 1px solid #000;
  background-color: var(--color-background-inverse);
  border-color: var(--color-border-inverse);
  color: var(--color-text-inverse);
  height: 50px;
  transform: translate(-3px,-3px);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.add-to-cart_button::after {
  border-right: 1px solid var(--color-background-inverse);
  border-top: 1px solid var(--color-background-inverse);
  height: 100%;
  right: -3px;
  top: 3px;
  width: 3px;
  content: "";
  display: block;
  position: absolute;
}

.add-to-cart_button::before {
  border-bottom: 1px solid var(--color-background-inverse);
  border-left: 1px solid var(--color-background-inverse);
  bottom: -3px;
  height: 3px;
  left: 3px;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
}


.add-to-cart_button:hover {
  color: rgb(92, 92, 92);
}

.add-to-cart_button:disabled {
  color: rgb(255, 255, 255);
  cursor: no-drop;
}

.short-description {
  font-size: 12px;
  margin-bottom: 3em;
}

.short-description p {
  margin-bottom: 10px;
  white-space: pre-line;
  line-height: 2em;
}

.short-description_model {
  font-size: 12px;
  font-style: italic;

}


@media (min-width: 1050px) {
  .grid-col-span-8 {
      grid-column: span 8;
  }
  
  .grid-col-span-9 {
      grid-column: span 9;
  }
  
  .main-layout {
      grid-template-columns: repeat(9, 1fr);
  }
  
  
  .nav-bar {
      height: 95px;
      padding: 25px 0;
      margin-bottom: 50px;
      z-index: 0;
  }

  .nav-bar_fixed {
      background-color: transparent;
  }
  
  .nav-bar_holder {
      display: flex;
      width: 95%;
      margin: 0 auto;
  }

  .nav-burger_holder {
    width: 0;
    visibility: hidden;
  }

  .logo-holder {
      position: relative;
      width: 50%;
  }

  .cart-drawer {
    width: 400px;
  }

  .cart-holder {
      width: 50%;
  }

  .cart-drawer.close {
    right: -100vw;
    animation: slideOut 0.3s;
    animation-fill-mode: forwards;
  }
  
  .cart-drawer.open {
    right: -100vw;
    animation: slideIn 0.3s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slideIn {
    from { right: -100vw; }
    to { right: 0; }
  }
  
  @keyframes slideOut {
    from { right: 0; }
    to { right: -100vw; }
  }
  
  .logo {
      position: absolute;
      top: 22px;
      width: 120px;
      transform: none;
  }

  .logo img {
      width: 120px;
  }
  
  .menu-bar {
      visibility: initial;
      height: initial;
      opacity: 1;
  }

  .products {
      padding-right: 10vw;
  }

  .products_list .products_item {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }

  .product-detail {
    padding-right: 10vw;
    grid-template-columns: repeat(2, 1fr);
  }

  .carousel_button {
    background-color: var(--secondary-button-bg-color);
  }
}
